import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import { IMaskInput } from "react-imask";
import ButtonGroup from "@mui/material/ButtonGroup";
import { blipClient, enviarMSG } from './Chatbot';
import { useRef } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const [open, setOpen] = React.useState(false);
  const mask = [{ mask: "(00) 0000-0000" }, { mask: "(00) 00000-0000" }];
  const inputProps = {
    IMaskInput,
  };
  const ref = useRef(null);
  const inputRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCtaClick = (e) => {
    e.preventDefault();
    if (inputRef.current.value.length > 13){
      var phoneNumber = inputRef.current.value;
      enviarMSG(phoneNumber);
    }
    else{
      alert("Parece que o seu número está incompleto");
    }
    
  };

  return (
    <div>
      <button
        className="w-full py-2 font-semibold rounded dark:bg-red-500 text-white-900"
        variant="outlined"
        onClick={handleClickOpen}
      >
        Investir Agora
      </button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Obtenha vantagem</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>Não perca a data dos melhores lançamentos</b>
          </DialogContentText>
          <IMaskInput
            mask={mask}
            unmask={true}
            ref={ref}
            inputRef={inputRef}
            value=""
            name="phone"
            type="text"
            placeholder="Qual seu Whatsapp?"
          />
        </DialogContent>
        <DialogActions>
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
          >
            <Button
              style={{ alignContent: "center" }}
              variant="contained"
              color="success"
              onClick={handleCtaClick}
            >
              Enviar
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
}
