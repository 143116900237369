// import house images

// import house large images
import LuzzVista from './assets/img/apartments/vista-longe.jpg';

// import agents images
import Agent from './assets/img/agents/anacut.png';

export const housesData = [
  {
    id: 1,
    type: 'Apartamento',
    name: 'House 2',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet, illoat. Repudiandae ratione impedit delectus consectetur. Aspernaturvero obcaecati placeat ab distinctio unde ipsam molestias atqueratione delectus blanditiis nemo eius dignissimos doloremque quaealiquid maiores id tempore consequatur, quod pariatur saepe.',
    image: LuzzVista,
    imageLg: LuzzVista,
    country: 'Zona Sul',
    address: 'Luzz',
    bedrooms: '3',
    bathrooms: '3',
    surface: '144m²',
    year: '2026',
    price: '7.800,00',
    agent: {
      image: Agent,
      name: 'Ana Cardoso',
      phone: '+55 34 99220-2474',
    },
  },
];
