import React from 'react';

import Image from '../assets/img/aninha.png';
import Radio from '../components/RowRadioButton';

const Banner = () => {
  return (
    <section className='h-full max-h-[840px] mb-8 xl:mb-24'>
      <div className="container grid gap-6 mx-auto text-center lg:grid-cols-2 xl:grid-cols-5">
		<div className="w-full px-6 py-16 rounded-md sm:px-12 md:px-16 xl:col-span-2">
			<span className="block mb-2 dark:text-pink-700">Ana Cardoso</span>
			<h1 className="text-5xl font-extrabold dark:text-black"><span className='text-pink-700'>Invista</span> em Uberlândia</h1>
			<p className="my-8">
				<span className="font-medium dark:text-black">Os melhores lançamentos da cidade.</span> Nas melhores localizações
			</p>
			<Radio className="self-stretch space-y-3 ng-untouched ng-pristine ng-valid"/>
		</div>
		<img src={Image} alt="" className="object-cover w-full rounded-md xl:col-span-2 hidden flex-1 lg:flex justify-end items-end" />
	</div>
    </section>
  );
};

export default Banner;
