import React from "react";

import { BlipChat } from "blip-chat-widget";

var message = "Oi";

const blipClient = new BlipChat();
blipClient.withAppKey(
  "YW5hY2FyZG9zb2Jyb2tlcjpkYmY4NmY5YS0yMmMxLTQ4NmUtOGEwNy1jNjMxMzVhYjMwOGQ="
);
blipClient.withButton({
  color: "#ffbf00",
  icon: "https://blipmediastore.blob.core.windows.net/public-medias/Media_9fc73a69-97c2-48c2-8b10-7409c3c9c3d8",
});
blipClient.withCustomCommonUrl("https://osb-tecnologia-y17ls.chat.blip.ai/");
blipClient.withEventHandler(BlipChat.LOAD_EVENT, function () {
  blipClient.sendMessage({
    type: "text/plain",
    content: message,
  });
});
blipClient.withoutHistory();
blipClient.build();

function enviarMSG(msg){
    message = msg;
    blipClient.toogleChat();
}; 

export {blipClient, enviarMSG}
